.gridjs-pages {
  width: 100%;
  display: flex;
}

/* source: https://github.com/wojtekmaj/react-pdf/blob/v6.x/sample/create-react-app-5/src/Sample.css */

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.react-pdf__message {
  padding: 20px;
  color: white;
}
